import { ReactNode, PropsWithChildren, ComponentProps } from 'react';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import Modal from './Modal';
import Navbar from './Navbar';
import NavbarIconButton from './NavbarIconButton';
import NavbarTitle from './NavbarTitle';
import Tiles from './Tiles';
import Button from './Button';

type ConfirmDialogProps = PropsWithChildren<{
  show?: boolean;
  title?: string;
  onHide?: () => void;
  onConfirm: () => void;
  onDecline?: () => void;
  className?: string;
  confirmLabel?: ReactNode;
  declineLabel?: ReactNode;
  confirmColor?: ComponentProps<typeof Button>['color'];
  declineColor?: ComponentProps<typeof Button>['color'];
  disableConfirm?: boolean;
}>;

const ConfirmDialog = ({
  show = false,
  title = '',
  onConfirm,
  onDecline = null,
  confirmLabel = 'Okay',
  declineLabel = 'Cancel',
  confirmColor = 'primary',
  declineColor = 'gray-800',
  disableConfirm = false,
  onHide = () => {},
  className = '',
  children = null,
}: ConfirmDialogProps) => (
  <Modal show={show} onHide={onDecline || onHide} className={className}>
    <Navbar
      className="sticky z-40 top-0 inset-x-0 h-16 shrink-0 bg-white border-b border-gray-400 pr-4 md:pr-4"
      left={<NavbarTitle title={title} />}
      right={
        <NavbarIconButton
          as="button"
          type="button"
          icon={faTimes}
          onClick={onDecline || onHide}
          iconProps={{ size: 'lg' }}
        />
      }
    />
    <div className="p-6 md:p-8">
      <div className="rich-text">{children}</div>
      <div className="mt-8">
        <Tiles spacing="xs" align="right" columns={[2, null]}>
          <Button
            as="button"
            type="button"
            color={confirmColor}
            className="font-medium block w-full md:w-auto md:min-w-[11.75rem]"
            onClick={onConfirm}
            disabled={disableConfirm}
          >
            {confirmLabel}
          </Button>

          {onDecline && (
            <Button
              as="button"
              type="button"
              color={declineColor}
              className="font-medium block w-full md:w-auto md:min-w-[11.75rem]"
              onClick={onDecline}
              outline
            >
              {declineLabel}
            </Button>
          )}
        </Tiles>
      </div>
    </div>
  </Modal>
);

export default ConfirmDialog;
